import { API } from "../service/api";
import { DealershipConfiguration } from "../types/dealership_configuration";
import Storage from "../utilities/storage/storage";
import { StorageKeys } from "../utilities/storage/storage_keys";

export class Configuration {
  private static _configuration: DealershipConfiguration;

  static get closeDuration() {
    return this._configuration.closeDuration || 300;
  }

  static get awaitScriptExecution() {
    return this._configuration.awaitScriptExecution || false;
  }

  static get chatEnabled() {
    return this._configuration.chatEnabled || false;
  }

  static initialize = async () => {
    let configuration = Storage.getKeyData<DealershipConfiguration>(
      StorageKeys.CONFIGURATION
    );

    if (configuration) {
      Storage.extendExpiryForSession(StorageKeys.CONFIGURATION);
    } else {
      configuration = await API.getConfiguration();
      Storage.setKeyForSession(StorageKeys.CONFIGURATION, configuration);
    }

    this._configuration = configuration;
  };
}
